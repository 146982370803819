import React, {useEffect, useState} from 'react';
import FormFieldError from '../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../shared/uielements/ErrorMessages/ErrorMessages';
import { hostNameAndPort } from '../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { Helmet } from "react-helmet";
import actionType from '../../../redux-store/action-type';
import { Link } from 'react-router-dom';

const initialForm = {
    name: '',
    emailAddress: '',
    reason: '',
    message: '',
    userAnswer: '',
    expectedResult: Math.random() * 10,
    questionText: '1+1=',
    formSubmitted: false,
    errorMessage: '',
}

const Contact = (props) => {

    const [contactInfo, setContactInfo] = useState(initialForm)
    const [agree, setAgree] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
        
        if (props.reason === 'feedback') {
            setContactInfo(prevContactInfo => {
                return {...prevContactInfo, reason: 'Leave Feedback'};
            });
            
            props.onSelectReason('');
        }

        if (props.reason === 'enterprise') {
            setContactInfo(prevContactInfo => {
                return {...prevContactInfo, reason: 'Enterprise Membership'};
            });
            props.onSelectReason('');
        }
        
        // genRandQuestion();
    }, [])

   
    useEffect(() => {
        genRandQuestion();
    })

    const onChangeAgree = () => {
        setAgree(value => {
            return !value
        })
    }
  

    const changeProperty = (e, _prop, _limit, _propLabel) => {

        if (e.target.value.length > _limit) {
            return setContactInfo(prevContactInfo => {
                return {...prevContactInfo, errorMessage: getTranslatedPhrase(props.userLanguage, _propLabel)+' '+getTranslatedPhrase(props.userLanguage,'must be smaller than')+' '+_limit };
            });
        }

        const value = e.target.value;

        setContactInfo(prevContactInfo => {
            return {...prevContactInfo, [_prop]: value, errorMessage: ''};
        });
    }
 
    const infoIsValid = () => {
        let _ = contactInfo;
        let res = true, _err = '';

        if (!_.name) {
            _err = getTranslatedPhrase(props.userLanguage,'Name')+' '+getTranslatedPhrase(props.userLanguage,'is required');
            res = false;
        }
        if (!_.emailAddress) {
            _err = getTranslatedPhrase(props.userLanguage,'Email Address')+' '+getTranslatedPhrase(props.userLanguage,'is required');
            res = false;
        }
        if (!_.message) {
            _err = getTranslatedPhrase(props.userLanguage,'Message')+' '+getTranslatedPhrase(props.userLanguage,'is required');
            res = false;
        }
        if (!_.reason || _.reason === 'Select Reason') {
            _err = getTranslatedPhrase(props.userLanguage,'Please select valid reason');
            res = false;
        }
        if (!agree) {
            _err = getTranslatedPhrase(props.userLanguage,'Please agree to our terms and conditions');
            res = false;
        }
        if ( !/[-_.A-Za-z0-9]+@[a-z][a-z0-9.]*.[a-z]{2,10}/.test(_.emailAddress) ) {
            _err = getTranslatedPhrase(props.userLanguage,'Email address has invalid format');
            res = false;
        }
        if (_.formSubmitted) {
            _err = getTranslatedPhrase(props.userLanguage,'Message has been sent already');
            res = false;
        }
        if ( contactInfo.expectedResult !== Number(contactInfo.userAnswer) ) {
            _err = getTranslatedPhrase(props.userLanguage, 'Wrong answer');
            res = false;
        }

        setContactInfo(prevContactInfo => {
            return {...prevContactInfo, errorMessage: _err};
        });

        return res;
    }

    const onSubmitMessage = async (e) => {
        e.preventDefault();

        if (!infoIsValid() || !agree) {
            return;
        }

        let _data = new FormData();
        _data.append('name', contactInfo.name);
        _data.append('emailAddress', contactInfo.emailAddress);
        _data.append('reason', contactInfo.reason);
        _data.append('message', contactInfo.message)
        let options = {
                url: `${hostNameAndPort}/api/mail/contactme`,
                method: 'post',
                data: _data,
            };
        try {
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setContactInfo(prevContactInfo => {
                    return {...prevContactInfo, formSubmitted: true };
                });

            } else {
                setContactInfo(prevContactInfo => {
                    return {...prevContactInfo, errorMessage: getTranslatedPhrase(props.userLanguage,'Form could not be submitted') };
                });
            }
        } catch(err) {
            setContactInfo(prevContactInfo => {
                return {...prevContactInfo, errorMessage: getTranslatedPhrase(props.userLanguage,'Network error ERRFECNT01') };
            });
        }
    }

    const genRandQuestion = () => {
        let op1, op2;
        op1 = Math.ceil( Math.random() * 10 );
        op2 = Math.ceil( Math.random() * 10 );
        let c = document.getElementById('myCanvasForgotPassword');
        if (!c) return;
        if (contactInfo.questionText !== '1+1=') return;
        let ctx = c.getContext('2d');
        ctx.font = '13px Open Sans';
        ctx.fillStyle = '#000000';
        ctx.fillText('Please verify that you are human.', 10, 30);
        ctx.fillText(op1 + '+' + op2 + '=', 10, 60);

        setContactInfo(prevContactInfo => {
            return {...prevContactInfo, questionText: op1 + '+' + op2 + '=', expectedResult: op1 + op2 };
        });
    }

    const onChangeExpectedResult = (e) => {
        let a = e.target.value;
        if (a.length < 10) {
            setContactInfo(prevContactInfo => {
                return {...prevContactInfo, userAnswer: a };
            });
        }
    }

    return (
        <div className="main-100 contact">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact | ZakList</title>
                <meta name="description" content="Say hello, ask a question, or send us suggestions. We listen!" />
                <link rel="canonical" href="https://zaklist.com/contact" />
            </Helmet>

            <div className="main-100-header">
                <h1>{getTranslatedPhrase(props.userLanguage,'Contact')}</h1>
                <p>{getTranslatedPhrase(props.userLanguage,'Say hello, ask a question, or send us suggestions. We listen!')}</p>
            </div>

            <div className="contact-box">
                <input type="text" className="form-input-text" placeholder={getTranslatedPhrase(props.userLanguage,'Name')} value={contactInfo.name} onChange={(e) => changeProperty(e, 'name', 200, 'Name')} />
                <input type="email" className="form-input-text" placeholder={getTranslatedPhrase(props.userLanguage,'Email Address')} value={contactInfo.emailAddress} onChange={(e) => changeProperty(e, 'emailAddress', 100, 'Email Address')} />
                <div className="form-input-select">
                    <select value={contactInfo.reason} onChange={(e) => changeProperty(e, 'reason', 100, 'Select Reason')}>
                        <option value="Select Reason">{getTranslatedPhrase(props.userLanguage,'Select Reason')}</option>
                        <option value="General Information">{getTranslatedPhrase(props.userLanguage,'General Information')}</option>
                        <option value="Investment Opportunity">{getTranslatedPhrase(props.userLanguage,'Investment Opportunity')}</option>
                        <option value="Customer Service">{getTranslatedPhrase(props.userLanguage,'Customer Service')}</option>
                        <option value="Suggestion">{getTranslatedPhrase(props.userLanguage,'Suggestion')}</option>
                        <option value="Leave Feedback">{getTranslatedPhrase(props.userLanguage,'Leave Feedback')}</option>
                        <option value="Enterprise Membership">{getTranslatedPhrase(props.userLanguage,'Enterprise Membership')}</option>
                    </select>
                </div>
                <textarea placeholder={getTranslatedPhrase(props.userLanguage,'Your Message')} className="form-input-textarea" value={contactInfo.message} onChange={(e) => changeProperty(e, 'message', 1000, 'Message')} />

                <canvas id="myCanvasForgotPassword" height="60" width="250" style={{border: "0px"}} />
                <input type="number" className="form-input-text" value={contactInfo.userAnswer} onChange={onChangeExpectedResult} placeholder={getTranslatedPhrase(props.userLanguage,'Answer')}/>

                <div className="txtc fnts modal_body_functions_agree-terms form-input-lists">
                    <input type="checkbox" id="createAccountTerms" checked={agree} onChange={onChangeAgree} />
                    <label htmlFor="createAccountTerms">
                    <span><i className="fal fa-check" /></span> {getTranslatedPhrase(props.userLanguage, 'I have read and agree to the')} <Link target="_blank" to="/terms-of-service">{getTranslatedPhrase(props.userLanguage, 'terms of service')}</Link> and <Link target="_blank" to="/privacy">{getTranslatedPhrase(props.userLanguage, 'privacy policy')}</Link>.</label>
                </div>

                <FormFieldError>{contactInfo.errorMessage}</FormFieldError>
                <button className="button button-regular mgt-sm" onClick={onSubmitMessage}>{getTranslatedPhrase(props.userLanguage,'Send Message')}</button>
                <ErrorMessages
                    errorMessageClass={"error-message error-message-info"}
                    errorMessageTitle={getTranslatedPhrase(props.userLanguage,'Success')}
                    errorMessageBody={getTranslatedPhrase(props.userLanguage,'Your message has been sent')} show={contactInfo.formSubmitted}/>

            </div>
        </div>
    );

}


const mapStateToContactProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        reason: state.reason
    };
};

const mapDispatchToContactProps = (
    dispatch
) => {
    return {
        onSelectReason:(a)=> {
            dispatch({type: actionType.SELECT_REASON, reason:a})
        }
    };
};

export default connect(mapStateToContactProps, mapDispatchToContactProps)(Contact);
