import React from 'react';
import Rate from '../../../uielements/Engage/EngageButtons/RateButton';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { getTranslatedPhrase } from '../../../util/lang';
import { titleCase } from '../../../util/string';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import actionType from '../../../../redux-store/action-type';



function ListingMeta(props) {
    const loggedInUser = useSelector(state => state.loggedInUser);
    const userLanguage = useSelector(state => state.userLanguage);
    const history = useHistory();

    function getDateForEvent(f, l) {
        let f0 = moment(f);
        let f1 = moment(l);
        let result;

        if (f0.isSame(f1, 'days')) {
            result = f0.format('MMM D, YYYY');
        } else {
            result = f0.format('MMM D') + '-' + f1.format('MMM D, YYYY');
        }
        return result;
    }



    function formatTime(t) {
        let fullTime = t;
        let hours = Number(fullTime.substring(0, fullTime.indexOf(':')).trim());
        let timeType = 'AM';

        if (hours >= 12 && hours < 24) {
            hours = hours - 12;
            timeType = "PM"
        }

        if (!hours) hours += 12;

        let min = fullTime.substring(fullTime.indexOf(':') + 1).trim();
        let newTime = hours + ":" + min + " " + timeType;

        return newTime;
    }

    function onClickContact(e) {
        if (!loggedInUser) {
            props.onShowLoginModal();
            return;
        }
        if (loggedInUser.userId === props.authorId) {
            props.showAlertModal(getTranslatedPhrase(userLanguage, 'This feature is not available!'));
            return;
        }
        let u = {
            userId: props.authorId,
            userName: props.authorName,
            profileImage: props.authorProfileImage,
            slug: props.authorSlug
        }
        let m = window.location.href;
        let w = props.websiteSection;
        switch (w) {
            case 'realestate':
            case 'realestate-old':
                w = 'property';
                break;
            case 'store':
                w = 'product';
                break;
            case 'travel':
                w = 'travel listing';
                break;
            case 'events':
                w = 'event';
                break;
            default:
                break;
        }
        props.updateMessagesPageProps(u, getTranslatedPhrase(userLanguage, 'I am writing in regards to this') + ' ' + getTranslatedPhrase(userLanguage, w) + `: ${m}`);
        history.push('/chat');
    }


    let { websiteSection } = props;

    let subcategory = props.subcategory;
    if (!subcategory || subcategory === 'null') subcategory = '';
    else if (websiteSection.startsWith('realestate')) subcategory = '  /  ' + getTranslatedPhrase(userLanguage, subcategory);
    else subcategory = '  /  ' + getTranslatedPhrase(userLanguage, titleCase(subcategory.replace(/-/g, ' ')));

    let completeUrl;
    let newUrl = props.ticketSaleURL;

    completeUrl = newUrl;

    if (newUrl) {
        newUrl = newUrl.trim().replace(/\s/g, "");

        if (/^(:\/\/)/.test(newUrl)) {
            completeUrl = `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
            completeUrl = `http://${newUrl}`;
        }
    }

    // <Link to={`/${websiteSection}/${props.categoryName}`}>

    return (
        <div className="auto-listing-title-map">
            <div className="car-meta-info">
                <h1>{props.productDetailTitle}</h1>
                <div>{(websiteSection !== 'cars' && websiteSection !== 'events' && websiteSection !== 'realestate' && websiteSection !== 'realestate-old') && <Rate noStars={props.productStars} noReviews={props.reviewCount} />}</div>
            </div>


            <div className="product-main-contact">

                <div className="ProductDetailInfo">
                    by <Link className="capitalize" to={'/' + props.authorSlug}><strong>{props.authorName}</strong></Link>
                    &nbsp;/
                    in <strong className="capitalize">{props.categoryName.replace(/-/g, ' ')}</strong>
                    {subcategory}
                </div>

                {!props.EventAddress ? null : (
                    <div className="event-address-date">
                        <div className="featured-event_details_info"><i className="fal fa-location-arrow" />
                            <span>{(!props.buildingName || props.buildingName === 'null') ? '' : props.buildingName}<br />
                                {props.EventAddress}</span></div>
                        <div className="featured-event_details_info"><i className="fal fa-calendar-alt" /><span>On {getDateForEvent(props.startDate, props.endDate)} at {formatTime(props.startTime)} - {formatTime(props.endTime)}</span></div>
                    </div>
                )}


                {(!props.PropertyDetailAddress || props.EventAddress || props.pickUp === 'sell-online') ? null : (
                    <div className="touch-address"><i className="fal fa-location-arrow" /> {props.PropertyDetailAddress}</div>
                )}

                <div className="touch-contact">

                    {(!loggedInUser || (loggedInUser && loggedInUser.userId !== props.authorId)) &&
                        <button data-tip="Chat" onClick={onClickContact}><i className="fal fa-messages" /></button>
                    }

                    {!props.sellerPhone ? null :
                        (<a data-tip="Call" href={`tel:${props.sellerPhone}`}><i className="fal fa-phone-alt" /></a>)}

                    {!props.sellerEmail ? null : (
                        <a data-tip="eMail" href={`mailto:${props.sellerEmail}`}><i className="fal fa-envelope" /></a>)}

                    {!props.address ? null : (
                        <a target="_blank" data-tip="Get Directions" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${props.address}/`}><i className="fal fa-location-arrow" /></a>)}

                    {(!completeUrl || props.pickUp === "local-only") ? null : (
                        <a href={completeUrl} data-tip="See Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a>)}
                </div>
            </div>


            {newUrl && (websiteSection === 'store' || websiteSection === 'food' || websiteSection === 'events') && props.pickUp !== "local-only" && (
                <a href={newUrl} target="_blank" rel="noopener noreferrer" className="button button-clear button-small mgt-md" style={{ display: "inline-block" }}>Shop Now</a>
            )}
        </div>

    );
}


const mapDispatchToListingMetaProps = (
    dispatch
) => {
    return {
        updateMessagesPageProps: (u, m) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: u, messagesDefaultMessage: m }),
        onShowLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
        showAlertModal: (m) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m })
    };
};

export default connect(null, mapDispatchToListingMetaProps)(withRouter(ListingMeta));