import React, { useEffect }  from 'react';
import { Helmet } from "react-helmet";

const CopyrightNotice = props => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="main-100 terms-of-service">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Copyright Notice | ZakList</title>
                <meta name="description" content="ZakList Inc. All Rights Reserved." />
                <link rel="canonical" href="https://zaklist.com/copyright" />
            </Helmet>

            <div className="main-100-header">
                <h1>Copyright Notice</h1>
                <p>&copy; 2014 - {new Date().getFullYear()} ZakList Inc. All Rights Reserved. v4.1.3</p>
            </div>

            <div className="legal-text">
                <p>Except as permitted by the copyright law applicable to you, you may not reproduce or communicate any of the content on this website, including files downloadable from this website, <br />without the permission of the copyright owner.</p>
            </div>

        </div>
    );
}

export default CopyrightNotice; 