import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { formatPriceAndCurrency } from '../../../shared/util/number'
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import EngageBox from '../../../shared/uielements/Engage/EngageBox';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';
import { titleCase } from "../../../shared/util/string";


class EventsListingPreview extends Component {
    state = {
        afterDiscountPrice: ''
    }

    componentDidMount() {
        let c = this.props.currency;
        let p = this.props.price;
        let d = this.props.discountId;
        if (!!d) {
            let theDiscount = d.couponValue;
            if (d.couponCurrency === '%') {
                p = Math.round((p - p * theDiscount / 100) * 100) / 100;
            } else {
                // p = p - Number(theDiscount);
                theDiscount = theDiscount / 100;
                p = Math.round((p - Number(theDiscount)) * 100) / 100;
            }
            if (p !== 0) {
                this.setState({ afterDiscountPrice: formatPriceAndCurrency(p, c, ',') });
            }
        }
    }

    onClickItem = (e) => {
        let urlSlug = `/${this.props.section || 'events'}/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;
        if (this.props.onClick !== undefined) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        //   this.props.history.push(urlSlug || '/events'); // FIX DOUBLE BACK ISSUE
        this.props.pageShouldRefresh(urlSlug);
    }



    render() {
        let thisCouponValue = (!this.props.discountId) ? 0 : (this.props.discountId.couponCurrency === '%') ? Number(this.props.discountId.couponValue) : Math.floor(Number(this.props.discountId.couponValue) / 100) + (Number(this.props.discountId.couponValue) % 100) / 100;
        let saleButtonCta = (!!this.props.callForPricing || !this.props.addDiscount) ? getTranslatedPhrase(this.props.userLanguage, 'See Details') : ((this.props.addDiscount === 'coupon') ?
            getTranslatedPhrase(this.props.userLanguage, 'Get') + ' ' +
            formatPriceAndCurrency(thisCouponValue, this.props.discountId.couponCurrency, ',') + ' ' +
            getTranslatedPhrase(this.props.userLanguage, 'Coupon') : getTranslatedPhrase(this.props.userLanguage, 'Get Deal'));

        let buttonClassname = 'button button-clear button-small';
        if (!this.props.callForPricing && !!this.props.addDiscount)
            buttonClassname = 'button button-sale button-small';

        let { onClickOnFavorite = () => null } = this.props;

        let linkSlug = `/${this.props.section || 'events'}/${this.props.category}/${this.props.subcategory || 'other'}/${this.props.slug}`;

        return (
            <div className="category-list_item event-item-preview">

                {/* <button className="event-item-preview-image" onClick={this.onClickItem}>
                    <img src={this.props.featuredImage} alt={this.props.title} />
                
                    <div className="event-item-preview-image-date">
                            <span className="event-item-preview-image-date-month">{moment(this.props.startDate).format('MMM')}</span>
                            <span className="event-item-preview-image-date-day">{moment(this.props.startDate).format('D')}</span>
                            <span className="event-item-preview-image-date-time">- {this.props.startTime} -</span>
                    </div>
                </button> */}

                <Link className="event-item-preview-image" to={linkSlug} onClick={this.onClickItem}>
                    <img src={this.props.featuredImage} alt={this.props.title} />

                    <div className="event-item-preview-image-date">
                        <span className="event-item-preview-image-date-month">{moment(this.props.startDate).format('MMM')}</span>
                        <span className="event-item-preview-image-date-day">{moment(this.props.startDate).format('D')}</span>
                        <span className="event-item-preview-image-date-time">- {this.props.startTime} -</span>
                    </div>
                </Link>



                <div className="slp">
                    <div className="slp-pricing-header">
                        <div className="slp-pricing-header-price">
                            {(!!this.props.callForPricing && this.props.callForPricing === 'call-for-pricing')
                                ? (<p className="store-listing-preview_price">
                                    {getTranslatedPhrase(this.props.userLanguage, 'Call For Pricing')}
                                </p>)
                                : (this.props.pickUp === "free-entrance")
                                    ? (<p className="store-listing-preview_price">
                                        {getTranslatedPhrase(this.props.userLanguage, 'Free Entrance')}
                                    </p>)
                                    : (!this.props.addDiscount)
                                        ? (<p className="store-listing-preview_price">{this.props.price === '0'
                                            ? getTranslatedPhrase(this.props.userLanguage, 'FREE')
                                            : formatPriceAndCurrency(this.props.price, this.props.currency, ',' || '')}
                                        </p>)
                                        : (<p className="store-listing-preview_price">{!this.state.afterDiscountPrice
                                            ? getTranslatedPhrase(this.props.userLanguage, 'FREE')
                                            : this.state.afterDiscountPrice}
                                            <span className="deals-was-price">
                                                {formatPriceAndCurrency(this.props.price, this.props.currency, ',' || '')}
                                            </span>
                                        </p>
                                        )}
                        </div>
                    </div>

                    <p className="slp-title"> {this.props.title} </p>

                    <p className="slp-brand"><span>{getTranslatedPhrase(this.props.userLanguage, 'By')}</span> <strong>{titleCase(this.props.authorName)}</strong></p>

                    <p className="slp-brand" style={{ marginTop: '.4rem' }}><span style={{ display: 'inline-block', marginRight: '.2rem', marginLeft: '.3rem' }}><i className="fal fa-location-arrow" /></span> <strong>{this.props.city}, {this.props.state}</strong></p>

                    <div className="slp-buttons">
                        <Link className={buttonClassname} to={linkSlug} onClick={this.onClickItem}>{saleButtonCta}</Link>
                        <ListingPreviewAdminPanel
                            itemType="events"
                            itemSlug={this.props.slug}
                            itemId={this.props.itemId}
                            userIsAuthorized={this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service'))}
                        />
                    </div>

                    <div className="slp-social">
                        <EngageBox
                            websiteSection={"events"}
                            itemId={this.props.itemId}
                            authorId={this.props.authorId}
                            withComments={true}
                            hideAllComments={true}
                            onClickOnFavorite={onClickOnFavorite}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToEventsListingPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToEventsListingPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToEventsListingPreviewProps, mapDispatchToEventsListingPreviewProps)(withRouter(EventsListingPreview));
