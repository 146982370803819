import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { connect } from 'react-redux';
import { hostNameAndPort } from '../../../../shared/util/vars';
import ListLoader from '../../../../shared/util/list-loader';
import { getTranslatedPhrase } from '../../../../shared/util/lang';

const params = {
    slidesPerView: 'auto',
    initialSlide: 1,
    freeMode: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
    }
};

const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}


class ProfileNavigation extends Component {

    state = {
        items: {},
        labels: ['store', 'food', 'autos', 'realestate', 'jobs', 'services', 'travel', 'blogs', 'photos', 'videos', 'events', 'groups'],
        tabs: ['Products', 'Auto Listings', 'Foods', 'Properties', 'Jobs', 'Services', 'Travel', 'Videos', 'Blogs', 'Photo Galleries', 'Events', 'Groups'],
        tabNumbers: {},
        Loader: null,
        photos: [],
        user: {}
    }


    componentDidMount() {

        let s = window.location.pathname;

        let pathSize = s.split('/').length;

        if (pathSize > 2) {
            s = s.split('/')[1];

        } else if (pathSize <= 2) {
            s = s.slice(1);
        }

        this.initTabs();
        this.getNavigationItems(s);
    }

    componentDidUpdate() {
        let g = this.props.userId;
        let p = this.props.userIsAuthenticated;

        if (this.state.userIsAuthenticated !== p || this.state.userId !== g) {
            this.updateTabs();
            this.setState({ userId: g, userIsAuthenticated: p });

            let s = window.location.pathname;

            let pathSize = s.split('/').length;

            if (pathSize > 2) {
                s = s.split('/')[1];

            } else if (pathSize <= 2) {
                s = s.slice(1);
            }

            this.getNavigationItems(s);

            // if (!this.state.Loader || this.props.userId !== this.state.userId) this.initLoader();
        }
    }

    componentWillUnmount() {
        if (this.state.Loader) this.state.Loader.reset();
    }

    initLoader = () => {
        // let Loader = new ListLoader({
        //     cb: this.onLoad,
        //     options: {
        //         delay: 200000,
        //         count: 4,
        //         request: {
        //             url: '/api/user/preview/items',
        //             method: 'post',
        //             formParams: { filter: JSON.stringify({ author: this.props.userId, dealer: this.props.dealerId, realestateAgency: this.props.realestateAgency }) }
        //         }
        //     }
        // });
        // Loader.init();
        // this.setState({ Loader });
    }

    onLoad = () => {
        // if (!this.state.Loader) return;
        // this.setState({ items: this.state.Loader.items });
    }

    getNavigationItems = async (u) => {
        try {

            let _data = new FormData();
            _data.append('user', u);

            let options = {
                url: `${hostNameAndPort}/api/user/profile/navigation`,
                method: 'post',
                data: _data
            };

            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {

                // console.log('data', data)

                this.setState({ items: data.items })
            } else {
                console.log(data.error);
            }
        } catch (err) {
            console.log(err);
        }
    }

    initTabs() {
        let _tabs = this.state.tabs;
        // let a = {};
        let b = {};
        for (let i in _tabs) {
            // a[_tabs[i]] = false;
            b[_tabs[i]] = 0;
        }

        this.setState({
            // tabActive: a,
            tabNumbers: b,
        });
    }

    onClickHandler = (section) => {
        this.props.history.push(`/${this.props.slug}/${section}`)
    }

    updateTabs = async () => {
        try {

            let filter = JSON.stringify({ author: this.props.userId, dealer: this.props.dealerId, realestateAgency: this.props.realestateAgency });
            let _data = new FormData();
            _data.append('filter', filter);

            let opts = {
                url: hostNameAndPort + '/api/user/preview/count',
                method: 'post',
                data: _data
            };
            let result = await Axios(opts);
            let { data } = result;
            if (!data.error) {

                // console.log(data);

                let a = {};
                let isFirstItem = true;
                for (let i in data.count) {
                    if (data.count[i]) {
                        if (isFirstItem) {
                            a[i] = true;
                            isFirstItem = false;
                        }
                    } else a[i] = false;
                }

                this.setState({
                    tabActive: a,
                    tabNumbers: data.count
                });
            }
        } catch (err) {
            if (process.env.NODE_ENV) console.log(err);
        }
    }


    render() {


        let { items } = this.state;
        if (!items) items = {
            blogs: [],
            photos: [],
            videos: [],
            store: [],
            food: [],
            autos: [],
            realestate: [],
            jobs: [],
            services: [],
            travel: [],
            events: [],
            groups: []
        };


        // let photosCount = 0;

        // if (items.photos) {
        //     photosCount = items.photos.length;
        // }

        return (
            <div className="profile-new-nav" >
                <div className="profile-new-nav-box">
                    <button className="profile-new-button" onClick={() => this.onClickHandler('about')}><i className="fal fa-book-open" /> <span>{getTranslatedPhrase(this.props.userLanguage, 'About')}</span></button>
                </div>

                <ReviewsSwiperItem accountType={this.props.accountType} slug={this.props.slug} userLanguage={this.props.userLanguage} reviewCount={this.props.stats.noReviews} onClickHandler={this.onClickHandler} />
                <FavoritesSwiperItem slug={this.props.slug} isProfileOwner={this.props.isProfileOwner} userLanguage={this.props.userLanguage} totalFavorites={this.props.allFavCount} onClickHandler={this.onClickHandler} />
                

                <SectionSwiperItem section="autos" sectionLabel="Auto Listings" newItemUrl="/autos/new-auto-listing" newItemLabel={getTranslatedPhrase(this.props.userLanguage, "Sell Cars")} sectionClassName="auto-gradient" iconClassName="fal fa-car"
                    items={items.autos} itemCount={this.state.tabNumbers['Auto Listings']} isProfileOwner={this.props.isProfileOwner} onClickHandler={this.onClickHandler} />
            </div>
        )
    }
}





function ReviewsSwiperItem(props) {
    if (props.accountType === 'personal') return null;
    return (
        <div className="profile-new-nav-box">
            <Link to={'/' + props.slug + '/reviews'} className="profile-new-button"
                onClick={() => {
                    props.onClickHandler('about');
                    let a = document.createElement("A");
                    // a.href = "#reviews";
                    a.click();
                }}
            >
                <i className="fal fa-star" /> <span>{!props.reviewCount ? null : `${props.reviewCount}`} {getTranslatedPhrase(props.userLanguage, 'Reviews')}</span>
            </Link>
        </div>);
}

function FavoritesSwiperItem(props) {
    // {!props.totalFavorites?null:`(${props.totalFavorites})`} 
    if (!props.isProfileOwner) return null;
    return (
        <div className="profile-new-nav-box">
            <Link to={`/${props.slug}/favorites`} className="profile-new-button" onClick={() => props.onClickHandler('favorites')}>
                <i className="fal fa-bookmark" /> <span>{getTranslatedPhrase(props.userLanguage, 'Favorites')}</span>
            </Link>
        </div>);
}


function SectionSwiperItem(props) {


    // function displayThumbnail(items, pos) {
    //     if (!items || !items[pos]) return null;
    //     return (<div className="square-menu-item_box"><img src={items[pos].featuredImage} alt={items[pos].title} /></div>);
    // }

    // function displayThumbnailPlaceholder(item, pos, iconClassName) {
    //     if (!item || !item[pos]) return (
    //         <div className="square-menu-item_box"><i className={iconClassName} /></div>);
    //     else return null;
    // }

    if (!props.itemCount)
        if (!props.isProfileOwner) {
            return (
                <div className="profile-new-nav-box">
                    <button className="profile-new-button"><i className={props.iconClassName} />
                        <span className="viewer-cta">0 {getTranslatedPhrase(props.userLanguage, props.sectionLabel)}</span>
                    </button>
                </div>
            );
        }
        else return (
            <div className="profile-new-nav-box">
                <Link to={props.newItemUrl || '/'} className="profile-new-button"><i className={props.iconClassName} />
                    <span className="viewer-cta">{getTranslatedPhrase(props.userLanguage, props.newItemLabel)}</span>
                </Link>
            </div>
        );

    return (
        <div className="profile-new-nav-box" onClick={(e) => props.onClickHandler(props.section)}>
            <button className="profile-new-button">
                {/* {displayThumbnail(props.items, 0)}
                {displayThumbnail(props.items, 1)}
                {displayThumbnail(props.items, 2)}
                {displayThumbnail(props.items, 3)} */}
                {/* {displayThumbnailPlaceholder(props.items, 3, props.iconClassName)}
                {displayThumbnailPlaceholder(props.items, 2, props.iconClassName)}
                {displayThumbnailPlaceholder(props.items, 1, props.iconClassName)} */}
                {/* <div className={"square-menu-item-icon " + props.sectionClassName} /> */}
                <i className={props.iconClassName} />
                <span>{props.itemCount} {getTranslatedPhrase(props.userLanguage, props.sectionLabel)}</span>
            </button>
        </div>
    );
}


const mapStateToProfileNavigation = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToProfileNavigation)(withRouter(ProfileNavigation));