import React, { Component } from "react";
import Swiper from "react-id-swiper";
import ListingMediaModal from "../../../components/Modals/Media/ListingMediaModal";
import ReactTooltip from "react-tooltip";
import moment from 'moment';
import ListingMeta from './ListingMeta';
import { Link } from "react-router-dom";
import { formatPriceAndCurrency, displayNumber } from "../../../util/number";
import AutoExteriorColor from "../../../../pages/Listings/Autos/Assets/auto-exterior-color.svg";
import EngageBox from "../../../../shared/uielements/Engage/EngageBox";
import { titleCase } from "../../../util/string";
import { getTranslatedPhrase } from "../../../util/lang";
import DefaultImage from "../../../../assets/default/default-events-listing-preview.jpg";
import DefaultAutoImage from "../../../../assets/default/default-auto-listing-preview.jpg";
import RealEstateFloorSize from "../../../../pages/Listings/RealEstate/Assets/realestate-surface.svg";
import CBALogo from '../../../../pages/Listings/RealEstate/Assets/cba-logo-white.png';
import { toHttps } from "../../../util/toHttps";

class ListingHeader extends Component {
    state = {
        openModal: false, 
        currentPhoto: -1,
        photos: [],
        a: ''
    };

    componentDidMount() {

        let a = this.props.address.trim();

        if (!a) {
            a = "0"; //Empire State Building';
        }

        a = a.replace(/\W(ste|Ste|Suite|SUITE|apt|Apt|APT|#).?\s?\d+/g, "");
        a = a.replace(/\s\s*/g, "+");
        a = a.replace(/&/g, "and");

        const photoList = [...this.props.photos];
        const cleanPhotos = [];

        photoList.forEach(el => {
            cleanPhotos.push(el.replace(/\\/g, ''));
        })

        this.setState({ photos: cleanPhotos, a: a });
    }

    componentDidUpdate() {
        if (this.state.photos[0] !== this.props.photos[0])
            this.setState({ photos: this.props.photos });
    }

    onClickPhoto = (i) => {
        this.setState({
            openModal: true,
            currentPhoto: i
        });
    };

    onCloseMediaModal = () => {
        this.setState({
            openModal: false
        });
    };

    goBack = () => {
        let c = this.state.currentPhoto;
        if (!c) {
            c = this.state.photos.length - 1;
        } else {
            c = c - 1;
        }

        this.setState({
            currentPhoto: c
        });
    };

    goForward = () => {
        let c = this.state.currentPhoto + 1;
        if (c >= this.state.photos.length) {
            c = 0;
        }

        this.setState({
            currentPhoto: c
        });
    };

    addDefaultSrc = (ev) => {
        // const cleanPhotos = this.state.photos.filter(img => img !== ev.target.src);
        // this.setState({ photos: cleanPhotos });

        ev.target.parentElement.remove();
    };

    addDefaultSingleSrc = (ev) => {
        if (this.props.websiteSection === "autos" || this.props.websiteSection === "cars") {

            if (this.props.carPhotoUrl) {
                ev.target.src = toHttps(this.props.carPhotoUrl);
                this.props.photos[0] = toHttps(this.props.carPhotoUrl);
            } else {
                ev.target.src = DefaultAutoImage;
                this.props.photos[0] = DefaultAutoImage;
            }

        } else {
            ev.target.src = DefaultImage;
            this.props.photos[0] = DefaultImage;
        }
    }

    render() {
        const params = {
            slidesPerView: "auto",
            initialSlide: 0,
            keyboard: {
                enabled: !this.state.openModal,
                onlyInViewport: false
            },
            loop: true,
            rebuildOnUpdate: true,
            freeMode: false,
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        };



        if (!this.props.photos || !this.props.photos.length) {
            return null;
        }

        let timeOnMarket;

        if (this.props.listedDate) {
            timeOnMarket = moment().diff(moment(this.props.listedDate), 'days');
        }

        if (this.props.websiteSection === "cars") {
            if (this.props.carPhotoUrl) {
                this.props.photos[0] = toHttps(this.props.carPhotoUrl);
            } else {
                this.props.photos[0] = DefaultAutoImage;
            }
        }

        return (

            <>
                <div style={{ display: 'none' }}>
                    <ReactTooltip place="bottom" type="info" effect="solid" backgroundColor="#555555" />
                </div>

                <div className={`ProductDetailHeader ${this.props.pickUp === 'sell-online' ? 'ProductDetailHeader-SellOnline' : 'null'}`}>
                    <div className={`ProductDetailHeaderBox ${this.props.pickUp === 'sell-online' ? 'ProductDetailHeaderBox-Photo' : 'null'}`}>
                        <div
                            className={`product-listing-header-image ${this.props.websiteSection === "jobs" ? 'product-listing-header-image-jobs' : ''}`}>
                            {this.props.photos.length === 1 ?
                                <div key='first-photo' className="ProductDetailHeaderBoxinBox">
                                    <img onError={this.addDefaultSingleSrc} src={toHttps(this.props.photos[0])}
                                        alt="234093091" onClick={() => this.onClickPhoto(0)} />
                                </div> :
                                <Swiper {...params}>
                                    {this.state.photos.map((v, i) => (
                                        <div key={i}>
                                            <img onError={this.addDefaultSrc} src={toHttps(v)} alt={"Photo " + i}
                                                onClick={() => this.onClickPhoto(i)} />
                                        </div>
                                    ))}
                                </Swiper>
                            }
                        </div>

                        {/* <div className="cost-map">
                            <div className="auto-details-cost">
                                <div className="dealer-cost">
                                    {(!this.props.carPrice || this.props.carPrice === "Call for Pricing")
                                        ? <span className="call-for-pricing">Call for Pricing</span>
                                        : !this.props.afterDiscountPrice
                                            ? formatPriceAndCurrency(this.props.carPrice, this.props.carPrice !== "Call for Pricing" ? this.props.carCurrency : "", ",")
                                            : formatPriceAndCurrency(this.props.afterDiscountPrice, this.props.afterDiscountPrice !== "Call for Pricing" ? this.props.carCurrency : "", ",")
                                    }

                                </div>


                                {!!this.props.carPrice && (this.props.carPrice !== "Call for Pricing" && !this.props.addDiscount && this.props.sellerType === 'dealer') &&
                                    <div className="fsp-cost">
                                        ZSP: <span>{formatPriceAndCurrency(this.props.fspPrice, this.props.carCurrency, ",")}</span>
                                        <i data-multiline="true"
                                            data-tip="The ZakList Suggested Price (ZSP),<br> doesn't not reflect the value of the car,<br> or the deal offered by the dealer.<br> It is based on our assumption that this<br> is a good negotiation starting point."
                                            className="fal fa-info-circle" />
                                    </div>}

                                <div className="original-price">
                                    {!this.props.addDiscount ? null :
                                        <span className="product-cost-discount">
                                            {formatPriceAndCurrency(this.props.carPrice, this.props.carCurrency, ",")}
                                        </span>
                                    }
                                </div>
                            </div>

                            {this.props.carCondition &&
                                <div className="cost-map-icon">
                                    <img src={AutoExteriorColor} alt="car condition" />
                                    <p>Condition <br /><strong className="capitalize">{this.props.carCondition}</strong>
                                    </p>
                                </div>
                            }
                            {!!this.props.carDaysOnMarket &&
                                <div className="cost-map-icon">
                                    <i className="fal fa-calendar-alt" />
                                    <p>On Market<br /><strong>{this.props.carDaysOnMarket} Days</strong></p>
                                </div>
                            }
                        </div> */}
                    </div>

                    <div className="ProductDetailHeaderBox CarHeaderDetails">
                        <EngageBox
                            websiteSection={this.props.websiteSection}
                            itemId={this.props.itemId}
                            favoriteItemId={this.props.favoriteItemId}
                            authorId={this.props.authorId}
                            withComments={false}>
                            <button className="print-button" onClick={window.print}><i
                                className="fal fa-print" /></button>
                        </EngageBox>

                        <div className="car-header-about">
                            <h1><span className="capitalize">{this.props.carCondition}</span> - {this.props.carTitle}</h1>

                            <div className="cost-map">
                                <div className="auto-details-cost">
                                    <div className="dealer-cost">
                                        {(!this.props.carPrice || this.props.carPrice === "Call for Pricing")
                                            ? <span className="call-for-pricing">Call for Pricing</span>
                                            : !this.props.afterDiscountPrice
                                                ? formatPriceAndCurrency(this.props.carPrice, this.props.carPrice !== "Call for Pricing" ? this.props.carCurrency : "", ",")
                                                : formatPriceAndCurrency(this.props.afterDiscountPrice, this.props.afterDiscountPrice !== "Call for Pricing" ? this.props.carCurrency : "", ",")
                                        }

                                    </div>


                                    {/* {!!this.props.carPrice && (this.props.carPrice !== "Call for Pricing" && !this.props.addDiscount && this.props.sellerType === 'dealer' && this.props.author.fspShow) &&
                                        <div className="fsp-cost">
                                            ZSP: <span>{formatPriceAndCurrency(this.props.fspPrice, this.props.carCurrency, ",")}</span>
                                            <i data-multiline="true"
                                                data-tip="The ZakList Suggested Price (ZSP),<br> doesn't not reflect the value of the car,<br> or the deal offered by the dealer.<br> It is based on our assumption that this<br> is a good negotiation starting point."
                                                className="fal fa-info-circle" />
                                        </div>} */}

                                    <div className="original-price">
                                        {!this.props.addDiscount ? null :
                                            <span className="product-cost-discount">
                                                {formatPriceAndCurrency(this.props.carPrice, this.props.carCurrency, ",")}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="product-main-contact">
                                <div className="ProductDetailInfo">
                                by <Link className="capitalize" to={'/' + this.props.car.authorSlug}><strong>{this.props.sellerName}</strong></Link>
                                        &nbsp;/&nbsp;
                                        in <Link to={`/autos/${this.props.car.category}`}><strong>{titleCase(this.props.car.category)}</strong></Link>
                                        &nbsp;/&nbsp;
                                        {titleCase(this.props.car.subcategory || 'All')}
                                </div>

                                <div className="touch-address"><i className="fal fa-location-arrow" /> {this.props.address}</div>

                                <div className="touch-contact">
                                {/* {(!this.props.loggedInUser || (this.props.loggedInUser && this.props.loggedInUser.userId !== this.props.car.authorId)) &&
                                    <button data-tip="Chat" onClick={this.props.onClickContact}><i className="fal fa-messages" /></button>
                                } */}

                                {((!this.props.loggedInUser || (this.props.loggedInUser && this.props.loggedInUser.userId !== this.props.car.authorId)) && (this.props.author && (this.props.author.ownership && this.props.author.isDealer))) &&
                                    <button data-tip="Chat" onClick={this.props.onClickContact}><i className="fal fa-messages" /></button>
                                } 

                                {((!this.props.loggedInUser || (this.props.loggedInUser && this.props.loggedInUser.userId !== this.props.car.authorId)) && !this.props.author) &&
                                    <button data-tip="Chat" onClick={this.props.onClickContact}><i className="fal fa-messages" /></button>
                                } 

                                {!this.props.sellerPhone ? null :
                                    (<a data-tip="Call" href={`tel:${this.props.sellerPhone}`}><i className="fal fa-phone-alt" /></a>)}
 
                                {!this.props.sellerEmail ? null : (
                                    <a data-tip="eMail" href={`mailto:${this.props.sellerEmail}`}><i className="fal fa-envelope" /></a>)}

                                <a target="_blank" data-tip="Get Directions" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${this.props.address}/`}><i className="fal fa-location-arrow" /></a>

                                {/* {(!this.props.sellerWebsite || (this.props.sellerType !== "dealer")) ? null : (
                                    <a href={`https://www.${this.props.sellerWebsite}`} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a>)} */}
                                

                                {(!this.props.sellerWebsite || (this.props.sellerType !== "dealer")) ? null : (
                                    this.props.moreInfo 
                                    ? <a href={`${this.props.moreInfo}`} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a> 
                                    : <a href={`https://www.${this.props.sellerWebsite}`} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a>
                                )}

                                </div>

                            </div>
                        </div>

                       
                    </div>
                    

                   

                    {(this.state.currentPhoto === -1) ? null : (
                        <ListingMediaModal
                            active={this.state.openModal}
                            onClickCloseHandler={this.onCloseMediaModal}
                            photoElement={<img src={this.state.photos[this.state.currentPhoto]}
                                alt={this.state.photos[this.state.currentPhoto]} />}
                            showArrows={(this.state.photos.length > 1)}
                            goBack={this.goBack}
                            goForward={this.goForward}
                        // goBackWithArrowLeft={this.goBackWithArrowLeft}
                        />)}
                </div>
            </>
        );
    }

}

export default ListingHeader;
