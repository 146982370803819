import React from 'react';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import {connect} from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import { Helmet } from "react-helmet";


class ResetPassword extends React.Component {
    state = { 
        errorMessage: '',
        newPassword: '',
        confirmPassword: '',
        newPasswordWaitingForAnswer: false,
        changePasswordSaved: false,
        p: '',
        q: '',
        r: ''
    }

    componentDidMount() {
        let params = (new URL(window.location.href)).searchParams;
        this.setState({
            p: params.get('p'),
            q: params.get('q'),
            r: params.get('r'),
        });
        this.props.history.push('/reset-password');
        // if (this.props.accountLoginModalActive) this.props.hideLoginModal();
    }

    componentDidUpdate() {
        // if (this.props.accountLoginModalActive) this.props.hideLoginModal();
    }

    onUpdatePasswordHandler = async () => {
        if (!this.infoIsVerified()) {
            return;
        }
        if (this.state.changePasswordSaved) {
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage,'Your information is already saved') });
            return;
        }
 
        let _data = new FormData();
        _data.append('p', this.state.p);
        _data.append('q', this.state.q);
        _data.append('r', this.state.r);
        _data.append('newPassword', this.state.newPassword);
        this.setState({newPasswordWaitingForAnswer: true});
        let options = {
                url: `${hostNameAndPort}/api/mail/resetuserpassword`,
                method: 'post',
                data: _data
            };
        try { let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({
                    newPassword: '',
                    confirmPassword: '',
                    changePasswordSaved: true, 
                    newPasswordWaitingForAnswer: false });
                this.automaticUserLogin(data.login);
            }
            else {
                this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage, data.error), newPasswordWaitingForAnswer: false });
            }
        } catch(err) {
            // Network error
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage,'Network error ERRFERESPS01'), newPasswordWaitingForAnswer: false });
        }
    }

    automaticUserLogin = (loginInfo) => {
        let a = atob(loginInfo).split(',');
        let options = {
            url: `${hostNameAndPort}/login`,
            method: 'post',
            data: `username=${a[0]}&password=${a[1]}`,  
        };
        Axios(options)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                this.setState({errorMessage: '', username: '', password: ''});
                this.props.onLogin(data.user);
                this.props.history.push('/');
            }
            else {
                this.setState({errorMessage: getTranslatedPhrase(this.props.userLanguage,'Email or password does not match')});
            }
        })
        .catch(err => {
            this.setState({errorMessage: getTranslatedPhrase(this.props.userLanguage,'Network error ERRFERESPS02')});
        });
    }

    infoIsVerified() {
        let _ = this.state;
        let errorMsg = '', _verified = true;
        let rp = /[*|'";:,?`~!@#$%^&=)(><}{\\]/g;

        if ( rp.test(_.newPassword) ) {
            errorMsg = getTranslatedPhrase(this.props.userLanguage,'Your password can only contain letters and numbers');
            _verified = false;
        }
        if ( _.newPassword.length < 8 ) {
            errorMsg = getTranslatedPhrase(this.props.userLanguage,'Your new password must be longer than 8 characters');
            _verified = false;
        }
        if ( _.newPassword !== _.confirmPassword ) {
            errorMsg = getTranslatedPhrase(this.props.userLanguage,'Your passwords do not match');
            _verified = false;
        }
        this.setState({errorMessage: errorMsg});
        return _verified;
    }

    onChangeNewPassword = (e) => {
        if (e.target.value.length > 512) {
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage,'New password is too long') });
            return;
        }
        this.setState({ newPassword: e.target.value, errorMessage: '', changePasswordSaved: false });
    }

    onChangeConfirmPassword = (e) => {
        if (e.target.value.length > 512) {
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage,'Confirmed password is too long') });
            return;
        }
        this.setState({ confirmPassword: e.target.value, errorMessage: '', changePasswordSaved: false });
    }

    render() {
        return (
            <div className="main-100 forgot-password">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Reset Password | ZakList</title>
                    <meta name="description" content="Update Your Password." />
                    <link rel="canonical" href="https://zaklist.com/reset-password" />
                </Helmet>

                <div className="home-box">
                    <div className="visitor-header">
                        <div className="visitor-header_box visitor-header_box_sign-in">
                            <h1>{getTranslatedPhrase(this.props.userLanguage,'Update Your Password')}</h1>

                            <input type="password" className="form-input-text" placeholder={getTranslatedPhrase(this.props.userLanguage,'New Password')} value={this.state.newPassword} onChange={this.onChangeNewPassword}  />
                            <input type="password" className="form-input-text" placeholder={getTranslatedPhrase(this.props.userLanguage,'Confirm New Password')} value={this.state.confirmPassword} onChange={this.onChangeConfirmPassword}  />

                            <FormFieldError>{this.state.errorMessage}</FormFieldError>
                            <button className="button button-white mgt-md" onClick={this.onUpdatePasswordHandler}>{getTranslatedPhrase(this.props.userLanguage,'Update')}</button>
                            <LoadingSvg active={this.state.newPasswordWaitingForAnswer} />
                            <ErrorMessages 
                                errorMessageClass={"error-message error-message-info"} 
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage,'Success')} 
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage,'Your password has been updated')} show={this.state.changePasswordSaved}/>
                        </div>
                    </div>

                </div>
            </div>);
    }
}

const mapStateToResetPasswordProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        accountLoginModalActive: state.accountLoginModalActive
    };
};

const mapDispatchToResetPasswordProps = (
    dispatch
) => {
    return {
        hideLoginModal: () => {
            dispatch({type: actionType.LOGIN_MODAL, accountLoginModalActive: false});
        },
        onLogin: (u) => {
            dispatch({type: actionType.USER_LOGIN, userIsAuthenticated: true, loggedInUser: u });
        },
    };
};

export default connect(mapStateToResetPasswordProps, mapDispatchToResetPasswordProps)(ResetPassword);