import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import Helmet from 'react-helmet';
import Box from '../../../../shared/util/box';

class FollowRequests extends Component {
    state = {
        followRequestsList: [],
    }

    componentDidMount() {
        setTimeout( () => {
            this.getFollowRequestsDataFromServer();
        },500);
    } 

    getFollowRequestsDataFromServer = () => {
        let url = `${hostNameAndPort}/api/user/follow-requests`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                this.setState({ followRequestsList: data.followRequestsList });
            } else this.props.history.push('/');
        })
        .catch(err => {
            this.props.history.push('/');
        });
    }

    onApproveFollowRequest = ( _userId ) => {        
        let _newList = this.state.followRequestsList;
        _newList = _newList.filter(v => (v.userId !== _userId));
        this.setState({ followRequestsList: _newList });

        let _data = new FormData();
        _data.append('fromUserId', _userId);
        _data.append('confirmed', true); 

        let options = {
            url: `${hostNameAndPort}/api/user/follow`,
            method: 'post',
            data: _data
        };
        Axios(options)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                // No error
            }
        })
        .catch(err => {
            // Network error
        });
    }

    onRemoveFollowRequest = (_userId) => {
        let _newList = this.state.followRequestsList;
        _newList = _newList.filter(v => (v.userId !== _userId));
        this.setState({ followRequestsList: _newList });

        let _data = new FormData();
        _data.append('fromUserId', _userId);
        _data.append('confirmed', true);

        let options = {
            url: `${hostNameAndPort}/api/user/remove-follow-request`,
            method: 'post',
            data: _data
        };
        Axios(options)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                // No error
            }
        })
        .catch(err => {
            // Network error
        });
    }

    getRightEnding = (n) => {
        if (n===1) {
            return 'Follow Request';
        } else {
            return 'Follow Requests';
        }
    }

    render () {
        return (<Box>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Follow Requests | Zaklist</title>
                    <meta name="description" content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family." />
                    <link rel="canonical" href={"https://zaklist.com/follow-requests"} />
                </Helmet>
            <div className="main-100 follow-requests">

                <div className="main-100-header">
                    <h1>{getTranslatedPhrase(this.props.userLanguage,'You Have')} {this.state.followRequestsList.length} {getTranslatedPhrase(this.props.userLanguage,this.getRightEnding(this.state.followRequestsList.length))}</h1>
                    <p className="mainCta">{getTranslatedPhrase(this.props.userLanguage,'See who wants to follow you')}!</p>
                </div>

                <div className="follow-requests_content">
                    <div className="follow-requests_content_box">
                        {this.state.followRequestsList.map( (v,i) => (!v)?null:(<div className="follow-requests_content_box_listing" key={i}>
                            <div className="follow-requests_content_box_listing_meta">
                                <span><img src={v.profileImage} alt={v.userName} /></span> <Link to={'/'+v.slug}> {v.userName}</Link>
                            </div>

                            {/* <p>{(!!v.comment) && (v.comment)}</p> */}

                            <div className="follow-requests_content_box_listing_buttons">
                                <button className="smallButton" onClick={() => this.onApproveFollowRequest(v.userId)} >{getTranslatedPhrase(this.props.userLanguage,'Approve')}</button>
                                <button className="smallButton" onClick={() => this.onRemoveFollowRequest(v.userId)} >{getTranslatedPhrase(this.props.userLanguage,'Delete')}</button>
                            </div>
                        </div>) )}
                    </div>
                </div>

            </div>
        </Box>);
    }
}


const mapStateToFollowRequestsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToFollowRequestsProps, null)(FollowRequests);