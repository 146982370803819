import React, { Component } from 'react';
import LayoutSideBar from '../../../../shared/util/layout-sidebar';
import actionType from '../../../../redux-store/action-type';
import { connect } from 'react-redux';

// import FollowButton from '../../../../components/SideBar/Followone/Followone';
import FollowerList from '../../../../shared/components/SideBar/UserList/UserList';
import ForerunnerList from '../../../../shared/components/SideBar/UserList/UserList';
import YouMayFollow from '../../../../shared/components/SideBar/YouMayFollow/YouMayFollow';
import CouponsSideBar from '../../../../shared/components/SideBar/CouponsSideBar/CouponsSideBar';
import AccountStats from '../../../../shared/components/SideBar/AccountStats/AccountStats';
import CurrentAccType from '../../../../shared/components/SideBar/CurrentAccType/CurrentAccType'
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import { Link } from 'react-router-dom';
import Box from '../../../../shared/util/box';


class ProfileSidebar extends Component {
    state = {
        followers: [],
        follow: [],
        userId: '',
        contact: {},
        requestIsSent: false,
        unfollowStatus: true,
        toggleSidebar: false
    }

    componentDidMount() {
        let u = this.props.userId;
        this.setState({ userId: u, requestIsSent: this.props.requestIsSent });
        this.getFollowerList();
        this.getForerunnerList();
    }

    componentDidUpdate() {
        let u = this.props.userId;
        if (this.state.userId !== u) {
            this.setState({ userId: u, requestIsSent: this.props.requestIsSent });
            this.getFollowerList();
            this.getForerunnerList();
        }
    }

    getFollowerList = async () => {
        let _data = new FormData();
        _data.append('userId', this.props.userId);
        let options = {
            url: `${hostNameAndPort}/api/user/followers`,
            method: 'post',
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({ followers: data.followers });
            }
        } catch (err) {
            // Network error
        }
    }

    getForerunnerList = async () => {
        let _data = new FormData();
        _data.append('userId', this.props.userId);
        let options = {
            url: `${hostNameAndPort}/api/user/forerunners`,
            method: 'post',
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({ follow: data.follow });
            }
        } catch (err) {
            // Network error
        }
    }

    sendMessage = () => {
        let u = this.props.userToMessage;
        let m = getTranslatedPhrase(this.props.userLanguage, 'Hello') + ' 😀';
        this.props.updateMessagesPageProps(u, m);
    }

    onToggleSidebar = () => {
        this.setState({ toggleSidebar: true });

        setTimeout(() => {
            this.setState({ toggleSidebar: false });
        }, 500)
    }

    render() {
        return (
            <LayoutSideBar toggleSidebar={this.state.toggleSidebar}>

                {/* <AccountStats AccountLevel="Awesome" NoOfFollowers={this.state.followers.length} NoOfPosts={this.props.allCount} NoOfViews={this.props.totalViews} /> */}

                {(!!this.props.isProfileOwner) ? null : (
                    <div className="main-sidebar_box">
                        <Link className="button button-clear button-smsg full-width dsp-blk txtc" onClick={this.sendMessage} to="/chat">{getTranslatedPhrase(this.props.userLanguage, 'Send Message')}</Link>
                    </div>)}

                {!this.props.userIsAuthenticated ? null : (
                    <ForerunnerList
                        UserListTitle={<Box><span className="followers-number-title">{getTranslatedPhrase(this.props.userLanguage, 'Following')}</span> <span>{this.state.follow.length}</span></Box>}
                        userList={this.state.follow}
                        listIsLoaded={true}
                        onToggleSidebar={this.onToggleSidebar}
                    />)}

                {!this.props.userIsAuthenticated ? null : (
                    <FollowerList
                        UserListTitle={<Box><span className="followers-number-title">{getTranslatedPhrase(this.props.userLanguage, 'Followers')}</span> <span>{this.state.followers.length}</span></Box>}
                        userList={this.state.followers}
                        listIsLoaded={true}
                        onToggleSidebar={this.onToggleSidebar}
                    />)}

                {(!this.props.isProfileOwner) ? null : (<YouMayFollow userId={this.props.userId} />)}

                <CouponsSideBar section="users" />

                {/* <CurrentAccType show={!!this.props.isProfileOwner} /> */}

            </LayoutSideBar>
        );
    }
}

const mapStateToPersonalProfileSidebarProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToPersonalProfileSidebarProps = (
    dispatch
) => {
    return {
        updateMessagesPageProps: (id, m) => {
            dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: id, messagesDefaultMessage: m });
        },
    };
};

export default connect(mapStateToPersonalProfileSidebarProps, mapDispatchToPersonalProfileSidebarProps)(ProfileSidebar);