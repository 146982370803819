import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import actionType, { actions } from '../../../../../redux-store/action-type';
import { Link, useHistory, withRouter } from 'react-router-dom';
import axios from 'axios';
import { getTranslatedPhrase } from '../../../../util/lang';
import { hostNameAndPort } from '../../../../util/vars';

let NCI_ID = 0;
let followRequestCount = 0;
let notificationCount = 0;
let messageCount = 0;
let user = null; 

function setNotificationCount(value) {
    notificationCount = value;
}

function setFollowRequestCount(value) {
    followRequestCount = value;
}

function setMessageCount(value) {
    messageCount = value;
}

function setUser(value = { userId: '1234' }) {
    user = value;
}

function SignedInHeaderTools(props) {
    const [welcomeModalSeen, setWelcomeModalSeen] = useState(false);
    /* for mobile app */
    const [notificationCountTotal, setNotificationCountTotal] = useState(0);

    // Andrei Check 
    const [allNotificationsAreAdded, setAllNotificationsAreAdded] = useState(0);
    const [n, setN] = useState(0);
    const [firstTime, setFirstTime] = useState(0);
    const userLanguage = useSelector(state => state.userLanguage);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const signInRefresh = useSelector(state => state.signInRefresh);
    const history = useHistory();


    async function getUserInfo() {
        setFollowRequestCount(0);
        setNotificationCount(0);
        setMessageCount(0);
        setFirstTime(0);
        setUser(null);
        let result = await axios(hostNameAndPort + '/api/user/whoami');
        let { data } = result;
        if (!data.error) {
            let { user } = data;
            setUser(user);
            props.onLogin(user);

            clearTimeout(NCI_ID);
            NCI_ID = setTimeout(() => {
                getNotificationCount(
                    loggedInUser,
                    Date.now(),
                    {
                        setFollowRequestCount,
                        setNotificationCount,
                        setMessageCount,
                        setNotificationCountTotal,
                    })
            }, 500);
        } else if (typeof window.webkit !== "undefined") {
            /* For notifications on iOS */
            if (typeof window.webkit.messageHandlers === "undefined" ||
                typeof window.webkit.messageHandlers.MobileAppInterface === "undefined")
                return;
            window.webkit.messageHandlers.MobileAppInterface.postMessage({
                messageType: "notificationCountTotal",
                messageValue: 0
            });
        }
    }

    useEffect(() => {
        if (!loggedInUser && !firstTime) {
            setFirstTime(firstTime + 1);
            getUserInfo();
        }
    }, [])

    useEffect(() => {
        setN(Math.random() + Math.random());

        // console.log('refresh')

        clearTimeout(NCI_ID);
        NCI_ID = setTimeout(() => {
            getNotificationCount(
                loggedInUser,
                Date.now(),
                {
                    setFollowRequestCount,
                    setNotificationCount,
                    setMessageCount,
                    setNotificationCountTotal,
                })
        }, 5000);

    }, [signInRefresh]);

    useEffect(() => {
        if (loggedInUser && loggedInUser.userStatus === 'disabled' && !welcomeModalSeen) {
            props.onShowWelcomeModal();
            setWelcomeModalSeen(true);
        }

        let p = loggedInUser;
        let profileHasBeenUpdated = (!p) ? true : false;
        let q = {
            slug: '00000-22222',
            firstName: 'Abcde',
            lastName: 'Edcba',
            email: 'email000@mail.com',
            affiliateId: '',
            dealer_id: '',
            ListOfficeMlsId: '',
            PrimaryOfficeID: '',
            accountType: 'personal',
            profileImage: 'https://websitediag290.blob.core.windows.net/images/profile1611176087755',
            userId: '000000000111111111222222',
            userName: 'Abcde Edcba',
            userStatus: 'enabled'
        };
        if (user) q = user;
        if (p) {
            for (let i of Object.keys(p)) {
                if (p.hasOwnProperty(i) && p[i] !== q[i]) {
                    profileHasBeenUpdated = true;
                    break;
                }
            }
        }

        if (profileHasBeenUpdated) {
            clearTimeout(NCI_ID);
            if (!p) {
                setFollowRequestCount(0);
                setNotificationCount(0);
                setMessageCount(0);
                setWelcomeModalSeen(false);
                setUser(null);
                props.closeWelcomeModal();
                if (typeof window.webkit !== "undefined") {
                    /* For notifications on iOS */
                    if (typeof window.webkit.messageHandlers === "undefined" ||
                        typeof window.webkit.messageHandlers.MobileAppInterface === "undefined")
                        return;
                    window.webkit.messageHandlers.MobileAppInterface.postMessage({
                        messageType: "notificationCountTotal",
                        messageValue: 0
                    });
                }
            } else getUserInfo();
        }

    }, [loggedInUser]);

    async function getNotificationCount(userAuth, startTimeHeaderSignIn, func) {
        try {
            let timePassed = Date.now() - startTimeHeaderSignIn;
            let url = `${hostNameAndPort}/api/user/notifications`;
            let result = await axios(url);
            let { data } = result;
            if (!data.error) {
                let n = 0;
                func.setFollowRequestCount(data.followRequestsList.length);
                func.setNotificationCount(data.notificationsList.length);
                func.setMessageCount(data.messageCount);
                props.refreshNow(Date.now());

                // console.log('notifications', followRequestCount, notificationCount, messageCount)

                if (data && data.followRequestsList) {
                    n = data.followRequestsList.length;
                }
                if (followRequestCount !== n) {
                    let messageForApp = {
                        messageType: "followRequestCount",
                        messageValue: Number(n),
                    };
                    if (typeof window.MobileAppInterface !== "undefined") {
                        /* For notifications on Android */
                        window.MobileAppInterface.updateFollowRequestCount("" + n);
                    }
                    if (typeof window.webkit !== "undefined") {
                        /* For notifications on iOS */
                        if (typeof window.webkit.messageHandlers === "undefined" ||
                            typeof window.webkit.messageHandlers.MobileAppInterface === "undefined")
                            return;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage(messageForApp);
                        let nct = notificationCountTotal + n;
                        let aad = (allNotificationsAreAdded + 1) % 3;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage({
                            messageType: "notificationCountTotal",
                            messageValue: Number(nct)
                        });
                        if (aad === 0) {
                            func.setNotificationCountTotal(0);
                        } else {
                            func.setNotificationCountTotal(nct);
                        }
                        // func.setAllNotificationsAreAdded(aad);
                    }
                }


                n = 0;
                if (data && data.notificationsList) {
                    n = data.notificationsList.length;
                }
                if (notificationCount !== n) {
                    let messageForApp = {
                        messageType: "notificationCount",
                        messageValue: Number(n),
                    };
                    if (typeof window.MobileAppInterface !== "undefined") {
                        /* For notifications on Android */
                        window.MobileAppInterface.updateNotificationCount("" + n);
                    }
                    if (typeof window.webkit !== "undefined") {
                        /* For notifications on iOS */
                        if (typeof window.webkit.messageHandlers === "undefined" ||
                            typeof window.webkit.messageHandlers.MobileAppInterface === "undefined")
                            return;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage(messageForApp);
                        let nct = notificationCountTotal + n;
                        let aad = (allNotificationsAreAdded + 1) % 3;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage({
                            messageType: "notificationCountTotal",
                            messageValue: Number(nct)
                        });
                        if (aad === 0) {
                            func.setNotificationCountTotal(0);
                        } else {
                            func.setNotificationCountTotal(nct);
                        }
                        // func.setAllNotificationsAreAdded(aad);
                    }
                }

                n = data.messageCount;
                if (messageCount !== n) {
                    let messageForApp = {
                        messageType: "messageCount",
                        messageValue: Number(n),
                    };
                    if (typeof window.MobileAppInterface !== "undefined") {
                        /* For notifications on Android */
                        window.MobileAppInterface.updateMessageCount("" + n);
                    }
                    if (typeof window.webkit !== "undefined") {
                        /* For notifications on iOS */
                        if (typeof window.webkit.messageHandlers === "undefined" ||
                            typeof window.webkit.messageHandlers.MobileAppInterface === "undefined")
                            return;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage(messageForApp);
                        let nct = notificationCountTotal + n;
                        let aad = (allNotificationsAreAdded + 1) % 3;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage({
                            messageType: "notificationCountTotal",
                            messageValue: Number(nct)
                        });
                        if (aad === 0) {
                            func.setNotificationCountTotal(0);
                        } else {
                            func.setNotificationCountTotal(nct);
                        }
                        // func.setAllNotificationsAreAdded(aad);
                    }
                }
                clearTimeout(NCI_ID);
                if (!userAuth) {
                    func.setFollowRequestCount(0);
                    func.setNotificationCount(0);
                    func.setMessageCount(0);
                } else {
                    if (timePassed < 5000) {
                        NCI_ID = setTimeout(() => getNotificationCount(loggedInUser, Date.now(), func), 5000);
                    } else {
                        NCI_ID = setTimeout(() => getNotificationCount(loggedInUser, Date.now(), func), timePassed);
                    }
                }
            }
        } catch (err) {
            console.log('Error: ' + err)
        }
    }

    function onLogOut(e) {
        e.preventDefault();
        clearTimeout(NCI_ID);
        setFollowRequestCount(0);
        setNotificationCount(0);
        setMessageCount(0);
        // setFirstTime(0);
        setUser(null);
        history.push("/");
        props.onEndAuthentication();
        props.updateConversationStatus([]);

        let url = `${hostNameAndPort}/logout`;
        axios(url)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    if (typeof window.webkit !== "undefined") {
                        /* For notifications on iOS */
                        if (typeof window.webkit.messageHandlers === "undefined" ||
                            typeof window.webkit.messageHandlers.MobileAppInterface === "undefined")
                            return;
                        window.webkit.messageHandlers.MobileAppInterface.postMessage({
                            messageType: "notificationCountTotal",
                            messageValue: 0
                        });
                    }
                }
            });
    }


    if (!loggedInUser) {
        return null;
    }

    let combinedNotificationCount = notificationCount + followRequestCount;
    // console.log('received', followRequestCount, notificationCount, messageCount)

    return (
        <div className="header-tools">
            <div className="header-tools-user">
                {(!user) ? (<Link to="/">
                    <i class="fa-light fa-user"></i>
                </Link>) : (
                    <Link to={'/' + user.slug + '/about'}
                        onClick={() => props.pageShouldRefresh(user.slug)}
                        title={`${user.firstName} ${user.lastName}`}>
                        <i class="fa-light fa-user"></i>
                    </Link>)}
            </div>
            <div className="mobile-footer-menu">
                <div className="header-tools-notifications"><Link to="/notifications"><i className="fa-light fa-bell-on" />{!combinedNotificationCount ? null : (<span>{combinedNotificationCount}</span>)}</Link></div>
                <div className="header-tools-notifications"><Link to="/chat"><i className="fal fa-messages" />{!messageCount ? null : (<span>{messageCount}</span>)}</Link></div>
                <div className="header-tools-notifications"><Link to="/profile-update"><i className="fal fa-gears" /></Link></div>
            </div>
            <div>
                <form>
                    {/* <button type="button" className="toolText sign-out-desktop" onClick={onLogOut}>{getTranslatedPhrase(userLanguage, 'Sign Out')}</button> */}
                    <button type="button" style={{marginLeft: '1rem'}} className="toolText" onClick={onLogOut}><i className="fal fa-sign-out-alt" style={{fontSize: '1.8rem'}} /></button>
                </form>
            </div>
        </div>
    );
}



const mapDispatchToSignedInHeaderToolsProps = (
    dispatch
) => {
    return {
        onShowWelcomeModal: () => dispatch({ type: actionType.WELCOME_MODAL, welcomeModalActive: true }),
        closeWelcomeModal: () => dispatch({ type: actionType.WELCOME_MODAL, welcomeModalActive: false }),
        onLogin: (u) => dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: true, loggedInUser: u }),
        onEndAuthentication: () => dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: false, loggedInUser: null }),
        pageShouldRefresh: (p) => dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p }),
        updateConversationStatus: (conversationStatus) => dispatch({ type: actions.CONVERSATION_STATUS, conversationStatus }),
        refreshNow: (value) => dispatch({ type: actionType.SIGN_IN_REFRESH, signInRefresh: value })
    };
};

export default connect(null, mapDispatchToSignedInHeaderToolsProps)(withRouter(SignedInHeaderTools));