import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSvg from '../../shared/uielements/LoadingSpinner/LoadingSvg';
// import { splitBigLine } from '../../util/string';
import { getTranslatedPhrase } from '../../shared/util/lang';
import SearchSidebar from './SearchSidebar';
import Box from '../../shared/util/box';
import { withRouter } from 'react-router-dom';
import SectionLoader from '../../shared/util/section-loader-search';
import SpecialItem from '../../shared/components/Advertising/SpecialItem/SpecialItem';
import MemberComponent from './MemberComponent';
import Helmet from 'react-helmet';
import PageNumbers from '../../shared/uielements/Pagination/page-numbers';
import actionType from '../../redux-store/action-type';


class SearchResults extends Component {
    state = {
        items: null,
        Loader: null,
        tabs: ['Products', 'Foods', 'Auto Listings', 'Properties', 'Jobs', 'Services', 'Travel', 'Blogs', 'Photo Galleries', 'Videos', 'Events', 'Groups', 'Users'],
        tabActive: {},
        tabNumbers: {},
        oldSearchQuery: '',
        searchQuery: '',
        currentSection: '',
        currentPage: 1,
        itemsPerPage: 18,
        loaded: false,
        searchResults: 0
    }

    componentDidMount() {
        // Trigger the update
        this.initTabs();
        const urlParams = new URLSearchParams(window.location.search);
        let currentQuery = decodeURI(urlParams.get('q'));
        if (!this.props.searchQuery) {
            if (!!currentQuery) {
                let a = { keywords: currentQuery, country: 'Select Location' };
                this.props.onSubmitSearchQuery(JSON.stringify(a));
            }
            return;
        }
    }

    componentWillUnmount() {
        let a = { keywords: 'clear-input', country: 'Select Location' };
        this.props.onSubmitSearchQuery(JSON.stringify(a));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.Loader && !this.state.loaded) return;
        // Get the current page
        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;
        let currentSection = decodeURI(urlParams.get('section'));
        let currentQuery = decodeURI(urlParams.get('q'));
        let currentCountry = decodeURI(urlParams.get('country')) || 'Select Location';
        if (currentCountry === 'null') currentCountry = 'Select Location';

        let myQuery = JSON.parse(this.props.searchQuery || '{}').keywords;
        if ((!currentQuery || currentQuery === 'null' || currentQuery === 'undefined')) {
            if (myQuery) {
                if (myQuery === 'null') {
                    myQuery = '';
                    let a = { keywords: '', country: currentCountry };
                    this.props.onSubmitSearchQuery(JSON.stringify(a));
                }
            } else if (!!this.state.items) this.setState({ oldSearchQuery: this.state.searchQuery, searchQuery:'', searchResults: 0, tabNumbers: {}, items: null });
        }
        if (!myQuery) {
            if (!!currentQuery && currentQuery !== 'null') {
                let a = { keywords: currentQuery, country: currentCountry };
                this.props.onSubmitSearchQuery(JSON.stringify(a));
            }
            return;
        }
        if (currentQuery && currentQuery !== 'null' && myQuery !== currentQuery && (myQuery === 'clear-input' || this.state.searchQuery === this.props.searchQuery)) {
            let a = { keywords: currentQuery, country: currentCountry };
            this.props.onSubmitSearchQuery(JSON.stringify(a));
        }

        if (this.state.searchQuery !== this.props.searchQuery && myQuery && myQuery !== 'null' && myQuery !== 'clear-input') {
            let a = this.state.tabActive;
            for (let i in a)
                a[i] = false;
            if (currentSection && currentSection !== 'null') a[currentSection] = true;
            else {
                a['Products'] = true;
                currentSection = 'Products';
            }
            
            let propsQuery = JSON.parse(this.props.searchQuery || '{}');
            if (propsQuery.country !== currentCountry && propsQuery.country !== 'Select Location') currentCountry = propsQuery.country;
            propsQuery.country = currentCountry;
            
            this.props.history.push(this.props.match.url + `?q=${propsQuery.keywords}&page=${currentPage}&section=${currentSection}&country=${propsQuery.country}`);

            propsQuery = JSON.stringify(propsQuery);
            if (!this.state.searchQuery) this.setState({ oldSearchQuery: this.state.searchQuery, searchQuery: this.props.searchQuery });
            else this.setState({ loaded: false, searchResults: -1, tabActive: a, tabNumbers: {}, items: null, oldSearchQuery: this.state.searchQuery, searchQuery: this.props.searchQuery });
            if (!this.state.Loader) this.initLoader(currentPage, {
                filter: propsQuery,
                activeTab: currentSection,
                page: currentPage,
                noItems: false,
                geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
            });
            else this.state.Loader.updateFormParams({
                filter: propsQuery,
                activeTab: currentSection,
                page: currentPage,
                noItems: false,
                geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
            });
            return;
        }

        if (currentPage !== this.state.currentPage) {
            this.setState({
                currentPage,
                loaded: false
            });
            window.scrollTo(0,0);
            if (this.state.Loader) this.state.Loader.updatePage(currentPage);
        }
    }

    initLoader = (page, formParams) => {
        this.setState({
            Loader: new SectionLoader({
                section: 'search/all',
                cb: this.onLoad,
                options: {
                    page,
                    noItems: formParams.noItems,
                    pageCount: this.state.itemsPerPage,
                    formParams
                }
            })
        });
    }

    onLoad = ({items = null, totalCount = 0, error = ''}) => {
        if (error && (process.env.NODE_ENV === 'development')) {
            console.log(error);
            this.setState({ oldSearchQuery: this.state.searchQuery, loaded: true });
            return;
        }
        if (!this.state.Loader) return;
        window.scrollTo(0, 0);
        let tabNumbers = {...this.state.tabNumbers};
        let tabNumbersEmpty = true;
        let searchResults = 0;

        if (!items) items = this.state.items;
        if (Array.isArray(items)) items = null;

        for (let i in tabNumbers)
            if (tabNumbers[i]) {
                tabNumbersEmpty = false;
                break;
            }
        if (totalCount && (tabNumbersEmpty || this.state.oldSearchQuery !== this.state.searchQuery)) {
            tabNumbers =  {...totalCount};
        }

        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;
        let currentSection = decodeURI(urlParams.get('section'));

        if (items && currentSection && currentSection !== 'null') {
            let section = ['store', 'food', 'autos', 'realestate', 'jobs', 'services', 'travel', 'blogs', 'photos', 'videos', 'events', 'groups'];
            let lookFor = ['Products', 'Foods', 'Auto Listings', 'Properties', 'Jobs', 'Services', 'Travel', 'Blogs', 'Photo Galleries', 'Videos', 'Events', 'Groups'];

            for (let i=0;i<section.length;i++) {
                if (this.state.tabActive[lookFor[i]] && items[section[i]] && items[section[i]].length <= 18 && tabNumbers[lookFor[i]] < 25) tabNumbers[lookFor[i]] = items[section[i]].length;
            }
        }

        for (let i in tabNumbers)
            searchResults = searchResults + tabNumbers[i];

        let nextState = {
            loaded: true,
            items,
            tabNumbers,
            searchResults,
            oldSearchQuery: this.state.searchQuery
        };
        tabNumbersEmpty = true;
        for (let i in tabNumbers)
            if (tabNumbers[i]) {
                tabNumbersEmpty = false;
                break;
            }
        let itemsEmpty = true;
        if (!!items) {
            let itemsKeys = Object.keys(items);
            for (let i of itemsKeys)
                if (items[i].length) { itemsEmpty = false; break; }
        }
        if (itemsEmpty && tabNumbersEmpty) nextState.loaded = true;
        else if (itemsEmpty || tabNumbersEmpty) nextState.loaded = false;
        this.setState(nextState);

        if (!tabNumbers[currentSection]) {
            let t = '';
            for (let i in tabNumbers)
                if (!!tabNumbers[i]) {
                    t = i;
                    break;
                }
            this.changeTab(t, currentPage);
        } else if (!this.state.tabActive[currentSection]) this.changeTab(currentSection, currentPage);
    }

    onUpdatePageNumber = (n = 1) => {
        let a = this.state.tabActive;
        let t = '';
        for (let i in a) {
            if (a[i]) {
                t = i;
                break;
            }
        }
        const urlParams = new URLSearchParams(window.location.search);
        let q = decodeURI(urlParams.get('q'));
        let c = decodeURI(urlParams.get('country'));
        this.props.history.push(this.props.match.url + `?q=${q}&page=${n}&section=${t}&country=${c}`);
    }

    initTabs() {
        let _tabs = this.state.tabs;
        let a = {};
        let b = {};
        for (let i in _tabs) {
            a[_tabs[i]] = false;
            b[_tabs[i]] = 0;
        }

        this.setState({
            tabActive: a,
            tabNumbers: b,
        });
    }

    changeTab = (t, p) => {
        let a = this.state.tabActive;
        if (!t || t === 'null') return;
        for (let i in a) {
            a[i] = false;
        }
        a[t] = true;

        if (!p) p = 1;
        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;
        let currentSection = decodeURI(urlParams.get('section'));
        let q = decodeURI(urlParams.get('q'));
        let cc = decodeURI(urlParams.get('country')) || 'Select Location';
        
        let propsQuery = JSON.parse(this.props.searchQuery || '{}');
        let keywordsMatch = propsQuery.keywords === q;
        if (!propsQuery || !propsQuery.keywords || propsQuery.keywords === 'clear-input') propsQuery.keywords = q;
        if (!q || q === 'null') q = propsQuery.keywords;

        if (propsQuery.country !== 'Select Location') cc = propsQuery.country;
        if (cc === 'null') cc = 'Select Location';
        
        if (t === currentSection) p = currentPage;
        if (keywordsMatch && t === this.state.currentSection && p === this.state.currentPage) return;

        if (!currentSection || currentSection === 'null')
            this.props.history.replace(this.props.match.url + `?q=${q}&page=${p}&section=${t}&country=${cc}`);
        else this.props.history.push(this.props.match.url + `?q=${q}&page=${p}&section=${t}&country=${cc}`);
        
        this.setState({ tabActive: a, currentSection: t, currentPage: p, loaded: false });
        if (!this.state.Loader) this.initLoader(p, {
            filter: JSON.stringify(propsQuery),
            activeTab: t,
            noItems: false,
            geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
        });
        else this.state.Loader.updateFormParams({
            filter: JSON.stringify(propsQuery),
            activeTab: t,
            page: p,
            noItems: false,
            noCount: true,
            geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
        });
    }

    /*
        {(!sf['city'])?'':<Box>{' '+getTranslatedPhrase(this.props.userLanguage,'city')+' '}
            {splitBigLine(sf['city'], 10).map( v => <Box>{v}{v.length>=10?<br />:null}</Box> )}
        </Box>}
        {(!sf['state'])?'':<Box>{' '+getTranslatedPhrase(this.props.userLanguage,'state')+' '}
            {splitBigLine(sf['state'], 10).map( v => <Box>{v}{v.length>=10?<br />:null}</Box> )}
        </Box>}
        {(!sf['zipCode'])?'':<Box>{' '+getTranslatedPhrase(this.props.userLanguage,'zip code')+' '}
            {splitBigLine(sf['zipCode'], 10).map( v => <Box>{v}{v.length>=10?<br />:null}</Box> )}
        </Box>}
        {(!sf['country'])?'':<Box>{' '+getTranslatedPhrase(this.props.userLanguage,'country')+' '}
            {splitBigLine(sf['country'], 10).map( v => <Box>{v}{v.length>=10?<br />:null}</Box> )}
        </Box>}
    */
    render() {
        let sf = JSON.parse(this.props.searchQuery || '{}');
        const urlParams = new URLSearchParams(window.location.search);
        let q = decodeURI(urlParams.get('q'));
        if (sf.keywords === 'clear-input') sf.keywords = q;
        let currentTabClass = {
            'true': 'current',
            'false': ''
        };

        let { items } = this.state;
        if (!items) items = {
            blogs: [],
            photos: [],
            videos: [],
            store: [],
            food: [],
            autos: [],
            realestate: [],
            jobs: [],
            services: [],
            users: [],
            travel: [],
            events: [],
            groups: []
        };

        let noItemsToShow = true;
        let t = ['store', 'food', 'autos', 'realestate', 'jobs', 'services', 'travel', 'blogs', 'photos', 'videos', 'events', 'groups', 'users'];
        let lookFor = ['Products', 'Foods', 'Auto Listings', 'Properties', 'Jobs', 'Services', 'Travel', 'Blogs', 'Photo Galleries', 'Videos', 'Events', 'Groups', 'Users'];
        let currentSection = decodeURI(new URLSearchParams(window.location.search).get('section'));
        if (!currentSection) currentSection = 'Products';
        for (let i in lookFor)
            if (this.state.tabNumbers[lookFor[i]]) {
                noItemsToShow = false;
                break;
            }
        for (let i in lookFor)
            if (lookFor[i] === currentSection && items[t[i]] && items[t[i]].length) {
                noItemsToShow = false;
                break;
            }
        let stillWaiting = (!this.state.Loader || !this.state.loaded || noItemsToShow);
        if (this.state.loaded && noItemsToShow) stillWaiting = false;
        if (noItemsToShow && !this.state.searchResults) stillWaiting = false;
        if (!this.state.loaded) stillWaiting = true;
        if (this.state.loaded && (!currentSection || currentSection === 'null')) stillWaiting = false;
        if (!!this.props.searchQuery && !JSON.parse(this.props.searchQuery).keywords) stillWaiting = false;
        if (!!this.props.searchQuery && JSON.parse(this.props.searchQuery).keywords === 'clear-input' && !q) stillWaiting = true;
        
        // {splitBigLine(sf['keywords'], 15).map((v,i) => <Box key={v+i}>{v}{v.length >= 15 ? ' ' : null}</Box>)}

        return (
            <Box>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Search | ZakList</title>
                    <meta name="description" content="Search products and services on ZakList" />
                    <link rel="canonical" href="https://zaklist.com/search" />
                </Helmet>

                <div className="main-100 search-page-header">
                    <div className="main-100-header">
                        <h1 key="title-h1" style={{wordBreak:"break-word"}}>
                            {(stillWaiting || this.state.searchResults < 0) ? getTranslatedPhrase(this.props.userLanguage, 'Loading results for') : (<Box>
                                {(!this.state.searchResults) ? getTranslatedPhrase(this.props.userLanguage, 'No results for') : null}
                                {(this.state.searchResults === 1) ? getTranslatedPhrase(this.props.userLanguage, 'One result for') : null}
                                {(this.state.searchResults > 1) ? this.state.searchResults + ' ' + getTranslatedPhrase(this.props.userLanguage, 'results for') : null}
                            </Box>)}

                        &nbsp;"{sf['keywords']}"
                    </h1 >
                        <p>
                            {(!this.state.tabNumbers['Products']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Products']]} key={'tabActive'+0} onClick={e => this.changeTab('Products')}>({this.state.tabNumbers['Products']}) {getTranslatedPhrase(this.props.userLanguage, 'Products')}</button>)}
                            {(!this.state.tabNumbers['Foods']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Foods']]} key={'tabActive'+12} onClick={e => this.changeTab('Foods')}>({this.state.tabNumbers['Foods']}) {getTranslatedPhrase(this.props.userLanguage, 'Foods')}</button>)}
                            {(!this.state.tabNumbers['Auto Listings']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Auto Listings']]} key={'tabActive'+13} onClick={e => this.changeTab('Auto Listings')}>({this.state.tabNumbers['Auto Listings']}) {getTranslatedPhrase(this.props.userLanguage, 'Auto Listings')}</button>)}
                            {(!this.state.tabNumbers['Properties']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Properties']]} key={'tabActive'+1} onClick={e => this.changeTab('Properties')}>({this.state.tabNumbers['Properties']}) {getTranslatedPhrase(this.props.userLanguage, 'Properties')}</button>)}
                            {(!this.state.tabNumbers['Jobs']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Jobs']]} key={'tabActive'+2} onClick={e => this.changeTab('Jobs')}>({this.state.tabNumbers['Jobs']}) {getTranslatedPhrase(this.props.userLanguage, 'Jobs')}</button>)}
                            {(!this.state.tabNumbers['Services']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Services']]} key={'tabActive'+3} onClick={e => this.changeTab('Services')}>({this.state.tabNumbers['Services']}) {getTranslatedPhrase(this.props.userLanguage, 'Services')}</button>)}
                            {(!this.state.tabNumbers['Travel']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Travel']]} key={'tabActive'+7} onClick={e => this.changeTab('Travel')}>({this.state.tabNumbers['Travel']}) {getTranslatedPhrase(this.props.userLanguage, 'Travel')}</button>)}
                            {(!this.state.tabNumbers['Blogs']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Blogs']]} key={'tabActive'+4} onClick={e => this.changeTab('Blogs')}>({this.state.tabNumbers['Blogs']}) {getTranslatedPhrase(this.props.userLanguage, 'Blogs')}</button>)}
                            {(!this.state.tabNumbers['Photo Galleries']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Photo Galleries']]} key={'tabActive'+5} onClick={e => this.changeTab('Photo Galleries')}>({this.state.tabNumbers['Photo Galleries']}) {getTranslatedPhrase(this.props.userLanguage, 'Photo Galleries')}</button>)}
                            {(!this.state.tabNumbers['Videos']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Videos']]} key={'tabActive'+8} onClick={e => this.changeTab('Videos')}>({this.state.tabNumbers['Videos']}) {getTranslatedPhrase(this.props.userLanguage, 'Videos')}</button>)}
                            {(!this.state.tabNumbers['Events']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Events']]} key={'tabActive'+9} onClick={e => this.changeTab('Events')}>({this.state.tabNumbers['Events']}) {getTranslatedPhrase(this.props.userLanguage, 'Events')}</button>)}
                            {/* {(!this.state.tabNumbers['Groups']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Groups']]} key={'tabActive'+10} onClick={e => this.changeTab('Groups')}>({this.state.tabNumbers['Groups']}) {getTranslatedPhrase(this.props.userLanguage, 'Groups')}</button>)} */}
                            {(!this.state.tabNumbers['Users']) ? null : (<button className={currentTabClass[!!this.state.tabActive['Users']]} key={'tabActive'+6} onClick={e => this.changeTab('Users')}>({this.state.tabNumbers['Users']}) {getTranslatedPhrase(this.props.userLanguage, 'Users')}</button>)}
                        </p>
                    </div>
                </div>


                <div className="search-page-loading">
                    <LoadingSvg active={stillWaiting ||this.state.searchResults < 0} />
                </div>


                {/* <div className="search-page-content"> */}
                {/* <div className="listing-body"> */}
                <div className="category-body">
                    {(!this.state.tabActive['Properties']) ? null : (<div className="main-container real-estate_category">
                        <div className="category-list">
                            {(!items.realestate.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No properties that match this criteria were found') : null}
                            {items.realestate.map((v, i) => (<SpecialItem key={i} {...v} itemType="realestate" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Properties'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Products']) ? null : (<div className="main-container store-home">
                        <div className="category-list">
                            {(!items.store.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No products that match this criteria were found') : null}
                            {items.store.map((v, i) => (<SpecialItem key={i} {...v} itemType="store" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Products'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Foods']) ? null : (<div className="main-container store-home">
                        <div className="category-list">
                            {(!items.food.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No foods that match this criteria were found') : null}
                            {items.food.map((v, i) => (<SpecialItem key={i} {...v} itemType="food" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Foods'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Auto Listings']) ? null : (<div id="Auto Listings" className="main-container store-home">
                        <div className="category-list">
                            {(!items.autos.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No auto listings that match this criteria were found') : null}
                            {items.autos.map((v, i) => (<SpecialItem key={i} {...v} itemType={!!v.id?'cars':'autos'}  userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Auto Listings'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Jobs']) ? null : (<div className="main-container jobs-category">
                        <div className="category-list">
                            {(!items.jobs.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No jobs that match this criteria were found') : null}
                            {items.jobs.map((v, i) => (<SpecialItem key={i} {...v} itemType="jobs" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Jobs'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Services']) ? null : (<div className="main-container service-category">
                        <div className="category-list">
                            {(!items.services.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No services that match this criteria were found') : null}
                            {items.services.map((v, i) => (<SpecialItem key={i} {...v} itemType="services" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Services'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Blogs']) ? null : (<div className="main-container">
                        <div className="category-list">
                            {(!items.blogs.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No blogs that match this criteria were found') : null}
                            {items.blogs.map((v, i) => (<SpecialItem key={i} {...v} itemType="blogs" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Blogs'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Photo Galleries']) ? null : (<div id="Photo Galleries" className="main-container photo-gallery-category">
                        <div className="category-list">
                                {(!items.photos.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No photo galleries that match this criteria were found') : null}
                                {items.photos.map((v, i) => (<SpecialItem key={i} {...v} itemType="photos" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Photo Galleries'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Videos']) ? null : (<div className="main-container video-gallery-category">
                        <div className="category-list">
                            {(!items.videos.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No videos that match this criteria were found') : null}
                            {items.videos.map((v, i) => (<SpecialItem key={i} {...v} itemType="videos" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Videos'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Travel']) ? null : (<div className="main-container photo-gallery-category">
                        <div className="category-list">
                            {(!items.travel.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No travel listings that match this criteria were found') : null}
                            {items.travel.map((v, i) => (<SpecialItem key={i} {...v} itemType="travel" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Travel'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {(!this.state.tabActive['Events']) ? null : (<div className="main-container photo-gallery-category">
                        <div className="category-list">
                            {(!items.events.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No events that match this criteria were found') : null}
                            {items.events.map((v, i) => (<SpecialItem key={i} {...v} itemType="events" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Events'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    {/* {(!this.state.tabActive['Groups']) ? null : (<div className="main-container video-gallery-category">
                        <div className="category-list">
                            {(!items.groups.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No groups that match this criteria were found') : null}
                            {items.groups.map((v, i) => (<SpecialItem key={i} {...v} itemType="groups" userLanguage={this.props.userLanguage} onClick={() => null} />))}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Groups'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)} */}

                    {(!this.state.tabActive['Users']) ? null : (<div className="main-container ServicesBody">
                        <div className="category-list">
                            {(!!items.users && !items.users.length && !stillWaiting) ? getTranslatedPhrase(this.props.userLanguage, 'No users that match this criteria were found') : null}
                            {!!items.users && items.users.map((v, i) => <MemberComponent userLanguage={this.props.userLanguage} key={'member-' + i} v={v} />)}
                        </div>

                        <PageNumbers
                            count={Math.ceil(this.state.tabNumbers['Users'] / this.state.itemsPerPage)}
                            activePage={this.state.currentPage}
                            onClick={this.onUpdatePageNumber} />
                    </div>)}

                    <SearchSidebar />
                </div>
            </Box>
        );
    }
}

const mapStateToSearchResultsProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        searchQuery: state.searchQuery,
        userLanguage: state.userLanguage,
        clientLatitude: state.clientLatitude,
        clientLongitude: state.clientLongitude
    };
};

const mapDispatchToSearchResultsProps = (
    dispatch
) => {
    return {
        onSubmitSearchQuery: (s) => {
            dispatch({ type: actionType.VIEW_SEARCH_RESULTS, searchQuery: s });
        }
    };
};

export default connect(mapStateToSearchResultsProps, mapDispatchToSearchResultsProps)(withRouter(SearchResults));