import React, { Component } from "react";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../util/lang";
import { titleCase } from "../../../util/string";
import AccreditedLogo from "../../../../assets/business/foaccredited.svg";

const getIcon = v => {
  let a;
  switch (v) {
    case "Category":
      a = <i className="fal fa-folder" />;
      break;
    case "Address":
      a = <i className="fal fa-location-arrow" />;
      break;
    case "Address Line 2":
      a = <i className="fal fa-location-arrow" />;
      break;
    case "Customer Service Phone #":
      a = <i className="fal fa-phone" />;
      break;
    case "Created By":
      a = <i className="fal fa-user-circle" />;
      break;
    case "Joined":
      a = <i className="fal fa-handshake" />;
      break;
    case "Date":
      a = <i className="fal fa-calendar-alt" />;
      break;
    case "Member Count":
      a = <i className="fal fa-users" />;
      break;
    case "Website":
      a = <i className="fal fa-globe" />;
      break;
    case "Customer Service Email":
      a = <i className="fal fa-at" />;
      break;
    case "Payment":
      a = <i className="fal fa-credit-card" />;
      break;
    case "Owner":
      a = <i className="fal fa-user" />;
      break;
    case "Hours":
      a = <i className="fal fa-clock" />;
      break;
    case "profileID":
      a = <i className="fal fa-id-badge" />;
      break;
    default:
      a = v;
      break;
  }
  return a;
};

class ProfileInfoBox extends Component {
  getClickableLink(s, t) {
    if (!t) return s;
    switch (t) {
      case "Customer Service Email":
        return <a href={"mailto:" + s}>{s}</a>;
      case "Customer Service Phone #":
        return <a href={"tel:" + s}>{s}</a>;
      case "Website":
        let label = s.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];

        if (!/^https?:\/\/www./.test(s)) s = "https://www." + label;
        return (
          <a rel="noopener noreferrer" target="_blank" href={s}>
            {label}
          </a>
        );
      case "Address":
        let url = "https://www.google.com/maps/place/" + s.replace(/ /g, "+");
        return (
          <a rel="noopener noreferrer" target="_blank" href={url}>
            {s}
          </a>
        );

      default:
        return s;
    }
  }

  render() {
    if (
      !this.props.profileDetails ||
      !Object.keys(this.props.profileDetails).length
    ) {
      return null;
    }

    let d = Object.assign({}, this.props.profileDetails);

    // console.log('d', d)

    d["Address"] = "";
    if (!!d["City"]) {
      d["Address"] = d["Address"] + d["City"] + ", ";
    }
    if (!!d["State"] && d["State"] !== "n/a") {
      d["Address"] = d["Address"] + d["State"] + '\n';
    }
    if (!!d["Zip Code"]) {
      d["Address"] = d["Address"] + d["Zip Code"];
    }
    if (!!d["Country"]) {
      d["Address"] = d["Address"] + " " + d["Country"];
    }
    delete d["City"];
    delete d["State"];
    delete d["Zip Code"];
    delete d["Country"];
    delete d["Unit"];
    d["Address"] = getTranslatedPhrase(
      this.props.userLanguage,
      titleCase(d["Address"].replace(/-/g, " "))
    );
    if (!!d["Category"]) {
      d["Category"] = getTranslatedPhrase(
        this.props.userLanguage,
        titleCase(d["Category"].replace(/-/g, " "))
      );
    }


    return (
      <div className="main-sidebar_box">
        <h4>
          {getTranslatedPhrase(
            this.props.userLanguage,
            this.props.infoBoxTitle || "About"
          )}{" "}
          {/* <br />
          <span>{this.props.userName || ""}</span> */}
        </h4>

        <table>
          <tbody>
            {!this.props.isAccredited ? null : (
              <tr>
                <td>
                  <i className="fal fa-shield-check" />
                </td>
                <td className="accredited-business">
                  {" "}
                  <img
                    src={AccreditedLogo}
                    alt="This business is accredited by Followone"
                  />{" "}
                </td>
              </tr>
            )}

            {Object.keys(d).map(v => {
              // console.log(v);
              return !d[v] || d[v] === "n/a" ? null : (
                <tr key={v}>
                  <td>{getIcon(v)}</td>
                  <td style={v === 'profileID' ? {} : { whiteSpace: 'pre' }}>{this.getClickableLink(d[v], v)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProfileInfoBoxProps = (state, props) => {
  return {
    userLanguage: state.userLanguage
  };
};

export default connect(mapStateToProfileInfoBoxProps, null)(ProfileInfoBox);
